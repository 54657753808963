import router from '@/router'
import { Get } from '@core/api/PromoCode'
import { ref, watch } from '@vue/composition-api'

export default function usePromoCodeList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'Amount', value: 'Amount' },
    { text: 'Option', value: 'Option' },
    { text: 'Type', value: 'Type', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const PromoCodeListTable = ref([])
  const searchQuery = ref('')
  const BranchId = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const totalPromoCodeListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const promocodeTotalLocal = ref([])
  const loading = ref(false)
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchPromoCodes = () => {
    Get()
      .then(response => {
        // handle success
        PromoCodeListTable.value = response
        totalPromoCodeListTable.value = response.lenght
        promocodeTotalLocal.value = response.lenght
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        // remove loading state
        loading.value = false
        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true
    fetchPromoCodes()
  })
  return {
    PromoCodeListTable,
    tableColumns,
    searchQuery,
    BranchId,
    statusFilter,
    totalPromoCodeListTable,
    options,
    promocodeTotalLocal,
    fetchPromoCodes,
    loading,
  }
}
