<template>
  <v-navigation-drawer
    :value="isAddActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New </span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="PromoCodeData.Name"
            outlined
            dense
            :rules="[validators.required]"
            label="Name"
            placeholder="Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            type="Number"
            v-model="PromoCodeData.Amount"
            :rules="[validators.required]"
            label="Amount"
            prefix="$"
            @focus="$event.target.select()"
            class="mb-6"
          ></v-text-field>

          <v-radio-group row :rules="[validators.required]" v-model="PromoCodeData.Option" class="mb-6">
            <v-radio value="Percentage">
              <template v-slot:label>
                <div><strong class="success--text">Percentage %</strong></div>
              </template>
            </v-radio>
            <v-radio value="Value">
              <template v-slot:label>
                <div><strong class="primary--text">Value</strong></div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-select
            v-model="PromoCodeData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['promocodes']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>

          <v-select
            v-model="PromoCodeData.Type"
            :rules="[validators.required]"
            label="Type"
            :items="[
              { label: 'Discount', value: 'Discount' },
              { label: 'Increase', value: 'Increase' },
            ]"
            item-text="label"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
          <br />
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetPromoCodeData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { Create } from '@core/api/PromoCode'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isAddActive',
    event: 'update:is-add-active',
  },
  props: {
    isAddActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankPromoCodeData = {
      Id: undefined,
      Name: '',
      Amount: '',
      Status: 'active',
      Type: '',
      Option: '',
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const PromoCodeData = ref(JSON.parse(JSON.stringify(blankPromoCodeData)))
    const resetPromoCodeData = () => {
      PromoCodeData.value = JSON.parse(JSON.stringify(blankPromoCodeData))
      resetForm()
      emit('update:is-add-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        Create(PromoCodeData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-active', false)
        })
        resetPromoCodeData()
      } else {
        validate()
      }
    }

    return {
      resetPromoCodeData,
      form,
      onSubmit,
      PromoCodeData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
