<template>
  <v-dialog :value="isDialogEdit" max-width="800px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">Edit PromoCode : {{ PromoCode.Id }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="PromoCodeData.Name"
            outlined
            dense
            :rules="[validators.required]"
            label="Name"
            placeholder="Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            type="Number"
            v-model="PromoCodeData.Amount"
            :rules="[validators.required]"
            label="Amount"
            prefix="$"
            @focus="$event.target.select()"
            class="mb-6"
          ></v-text-field>
          <v-radio-group row v-model="PromoCodeData.Option" class="mb-6">
            <v-radio value="Percentage">
              <template v-slot:label>
                <div><strong class="success--text">Percentage %</strong></div>
              </template>
            </v-radio>
            <v-radio value="Value">
              <template v-slot:label>
                <div><strong class="primary--text">Value</strong></div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-select
            v-model="PromoCodeData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['promocodes']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
          <v-select
            v-model="PromoCodeData.Type"
            :rules="[validators.required]"
            label="Type"
            :items="[
              { label: 'Discount', value: 'Discount' },
              { label: 'Increase', value: 'Increase' },
            ]"
            item-text="label"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { Edit } from '@core/api/PromoCode'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    PromoCode: { type: Object, required: true },
  },
  watch: {
    PromoCode(New) {
      this.PromoCodeData = New
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      Name: '',
      Amount: '',
      Status: 'active',
      Type: '',
      Option: '',
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const PromoCodeData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetPromoCodeData = () => {
      PromoCodeData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-dialog-edit', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        Edit(PromoCodeData.value).then(() => {
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
        })

        resetPromoCodeData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetPromoCodeData()
    }

    return {
      resetPromoCodeData,
      form,
      onSubmit,
      PromoCodeData,
      valid,
      validate,
      mdiPlus,
      close,
      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
